// Navbar.js

import React from 'react';
import Logo from './Logo';
import './Navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <Logo />
    </div>
  );
};

export default Navbar;
