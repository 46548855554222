import React from 'react';
import './LoadingText.css';

const LoadingText = () => {
  return (
    <div className="loading-text">
      <h1>Loading...</h1>
    </div>
  );
};

export default LoadingText;
