import React from "react";
import LoadingText from "./LoadingText";
import Logo from "./Logo";
import Navbar from "./Navbar";

const App = () => {
  // You can modify the loading state as needed
  const isLoading = true;

  return (
    <div>
      <Navbar />
      {isLoading && <LoadingText />}
    </div>
  );
};

export default App;
